export default {
  cdn: `https://cdn-${process.env.ODYSSEY_ENV}.odyssey.design.fireeye.com/`,
  host: `https://${process.env.ODYSSEY_ENV}.odyssey.design.fireeye.com`,
  idp: {
    issuer: 'https://idp.fireeye.com',
  },
  apis: {
    myfireeye: 'https://my.fireeye.com/graphql',
  },
};
