import gql from 'graphql-tag';
import client from '../client';

const fetchUserData = async (gqlURI, accessTokenDecoded) => {
  const userId = accessTokenDecoded.uid;
  return client(gqlURI, accessTokenDecoded.accessToken).query({
    query: gql`
      query {
        userDetails(userId: "${userId}") {
          firstName
          lastName
          email
        }
        products(userId: "${userId}") {
          id
          label
          linkUrl
          displayName
          displayAssignable
        }
      }
    `,
  });
};

export default fetchUserData;
