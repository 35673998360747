import React, { lazy, Suspense, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CustomPropTypes from '../../common/customPropTypes';

const NotificationPanel = lazy(() => import('./NotificationPanel'));
const PopoverStyles = styled.div`
  .nav-popover {
    &__content {
      width: 350px;
      min-height: 125px;
      &:hover {
        cursor: default;
      }
    }
    &__spinner {
      display: flex;
      padding-top: 200px;
      padding-bottom: 200px;
    }
  }
`;
const popoverLoader = (
  <muse-spinner custom-class="nav-popover__spinner" size="lg">
    Loading
  </muse-spinner>
);

const NotificationPopover = ({ notifications }) => {
  const [showPanel, setShowPanel] = useState(false);
  const popoverRef = useRef(null);
  const notificationPanel = showPanel ? <NotificationPanel notifications={notifications} /> : null;
  const handleClick = () => setShowPanel(true);
  useEffect(() => {
    popoverRef.current.addEventListener('triggerClick', handleClick);
    return () => {
      if (popoverRef.current) popoverRef.current.removeEventListener('triggerClick', handleClick);
    };
  }, []);
  return (
    <PopoverStyles ref={popoverRef} style={{ maxHeight: 16 }}>
      <muse-popover trigger="click" placement="bottom-start" custom-class="nav-popover">
        <muse-icon
          name="bell-filled"
          slot="popover-trigger"
          aria-label="Toggle notification panel"
          role="button"
        />
        <div className="nav-popover__content">
          <Suspense fallback={popoverLoader}>{notificationPanel}</Suspense>
        </div>
      </muse-popover>
    </PopoverStyles>
  );
};

NotificationPopover.propTypes = {
  notifications: PropTypes.arrayOf(CustomPropTypes.notification.isRequired).isRequired,
};

export default NotificationPopover;
