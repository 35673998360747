import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import appLogos from './svgs';

const AppSwitcher = ({ userData }) => {
  const { apps = [] } = userData;
  const nameToId = id => id && id.replace(/\s+/g, '-').toLowerCase();

  const dropdownRef = useRef(null);
  const goToURI = e => {
    const {
      detail: { uri },
    } = e;
    e.stopPropagation();
    window.open(uri, '_blank');
  };

  useEffect(() => {
    const { current: ref } = dropdownRef;
    if (ref) ref.addEventListener('tileClick', goToURI, false);
    return () => {
      if (ref) ref.removeEventListener('tileClick', goToURI, false);
    };
  }, []);

  return (
    <muse-top-nav-dropdown slot="nav-app-switcher" is-util="true" ref={dropdownRef}>
      <muse-icon slot="top-nav-dropdown-trigger" name="grid" size="md" />
      <muse-app-switcher>
        {apps.map(({ id, name, displayName, linkUrl, label }) => {
          const appId = id || nameToId(name || label);
          const logoId = nameToId(name || label);
          const appLogo = appLogos[logoId];
          return (
            <muse-app-tile
              key={`app-tile--${appId}`}
              name={displayName || name}
              uri={linkUrl}
              data-testid="odysseyAppTile"
            >
              {appLogo || appLogos.myfireeye}
            </muse-app-tile>
          );
        })}
      </muse-app-switcher>
    </muse-top-nav-dropdown>
  );
};

AppSwitcher.propTypes = {
  userData: PropTypes.shape({
    apps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        linkUrl: PropTypes.string,
        displayName: PropTypes.string,
        name: PropTypes.string,
        label: PropTypes.string,
        displayAssignable: PropTypes.bool,
      })
    ),
  }),
};

AppSwitcher.defaultProps = {
  userData: {
    apps: [],
  },
};

export default AppSwitcher;
