import PropTypes from 'prop-types';

const CustomPropTypes = {
  location: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  notification: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
    isSeen: PropTypes.bool.isRequired,
    disposition: PropTypes.oneOf(['INFO', 'ERROR', 'SUCCESS', 'WARNING']).isRequired,
    source: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
};

export default CustomPropTypes;
