import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';

const AvatarMenuStyles = styled.div`
  .avatar-menu {
    width: 237px;
    font-size: 1.2rem;
    &__header {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--border--emphasis);
      padding: 16px;
      .muse-label {
        padding: 0;
      }
      select {
        text-transform: capitalize;
      }
      .muse-icon {
        margin: 0 6px 0 0;
      }
    }
    &__body {
      padding: 16px;
      &__option--disabled {
        .muse-select__icon {
          color: var(--text--disabled);
        }
        .square-border {
          color: var(--text--disabled);
        }
      }
      &__option {
        .muse-tooltip {
          position: relative;
          top: 4px;
          left: 2px;
          &__trigger {
            > div {
              height: 12px;
            }
          }
          .muse-popover {
            #tippy-1 {
              font-weight: 600;
              .tippy-box {
                width: 200px;
                box-shadow: none;
              }
            }
          }
        }
      }
      h4 {
        text-transform: uppercase;
        font-size: 8px;
        line-height: 16px;
        letter-spacing: 1.5px;
        margin-left: -8px;
      }
      h5 {
        line-height: 3.6rem;
        font-weight: 400;
        font-size: 1.2rem;
        .avatar-menu__option {
          float: right;
          margin: 0px;
          padding: 0px;
          select {
            font-size: 1.2rem;
            text-transform: capitalize;
            border-radius: 4px;
            padding: 5px 8px;
            padding-right: calc(20px * 3);
            margin-top: 6px;
          }
          .muse-select__icon {
            top: 5px;
          }
          select:disabled {
            color: var(--text--disabled);
          }
          .square-border {
            height: 24px;
            margin-left: 2px;
            &--active {
              border: 1px solid var(--border--emphasis);
              border-radius: 4px;
            }
          }
        }
      }
    }
    &__title-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    &__title {
      color: var(--text--emphasis);
      text-transform: capitalize;
    }
    &__avatar {
      margin-right: 16px;
      .muse-avatar__circle {
        background-color: var(--background-page);
        color: var(--text);
        font-weight: normal;
        margin-right: 0;
      }
    }
    &__link {
      padding: 0;
      text-transform: capitalize;
      font-size: 1.2rem;
      &--text {
        display: inline;
        line-height: inherit;
        height: 0;
      }
    }
    &__text {
      color: var(--text--emphasis);
      font-size: 1.2rem;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const themeClassNames = active =>
  classNames({
    'square-border': true,
    'square-border--active': active,
  });

const createCustomEvent = (name, target, data = null) =>
  new CustomEvent(name, {
    detail: {
      slotId: 'odyssey-avatar',
      targetElement: target,
      data,
    },
    bubbles: true,
  });

class AvatarMenu extends Component {
  constructor(props) {
    super();
    const { theme, locale } = props;
    this.state = { selectedTheme: theme, selectedLocale: locale };
  }

  componentDidUpdate(prevProps) {
    const { theme, locale } = this.props;

    if (prevProps.theme !== theme) {
      this.setSelectedTheme(theme);
    }
    if (prevProps.locale !== locale) {
      this.setSelectedLocale(locale);
    }
  }

  setSelectedLocale(locale) {
    this.setState({ selectedLocale: locale });
  }

  setSelectedTheme(theme) {
    this.setState({ selectedTheme: theme });
  }

  render() {
    const {
      displayName,
      locales,
      themes,
      avatarConfig: { yourSettings, profile },
    } = this.props;

    const handleClickSettings = ({ target }) => {
      const event = createCustomEvent('odysseySettingsClicked', target);
      document.dispatchEvent(event);
    };

    const handleClickProfile = ({ target }) => {
      const event = createCustomEvent('odysseyProfileClicked', target);
      document.dispatchEvent(event);
    };

    const handleThemeToggle = ({ target }) => {
      const { selectedTheme } = this.state;
      const toggledTheme = selectedTheme === 'light' ? 'dark' : 'light';
      this.setState({ selectedTheme: toggledTheme });
      const event = createCustomEvent('odysseyThemeChanged', target, { theme: toggledTheme });
      document.dispatchEvent(event);
    };

    const handleLocaleChange = ({ target }) => {
      this.setState({ selectedLocale: target.value });
      const event = createCustomEvent('odysseyLocaleChanged', target, { locale: target.value });
      document.dispatchEvent(event);
    };

    const handleClickLogout = ({ target }) => {
      const event = createCustomEvent('odysseyLogoutClicked', target);
      document.dispatchEvent(event);
    };

    const { selectedTheme, selectedLocale } = this.state;

    const themesAvailable = themes && themes.length > 1;

    const localesAvailable = locales && locales.length > 1;

    return (
      <AvatarMenuStyles>
        <section className="avatar-menu">
          <header className="avatar-menu__header">
            <div className="avatar-menu__title-wrap">
              <muse-avatar
                slot="top-nav-dropdown-trigger"
                user-name={displayName}
                custom-class="avatar-menu__avatar"
              />
              <h5 className="avatar-menu__title">{displayName}</h5>
            </div>
            {yourSettings && yourSettings.show && (
              <muse-button
                button-type="link"
                custom-class="avatar-menu__link"
                onClick={handleClickSettings}
                name="odysseyAvatarSettings"
              >
                <muse-icon name="gear" size="md" />
                Your Settings
              </muse-button>
            )}
            {profile && profile.show && (
              <muse-button
                button-type="link"
                custom-class="avatar-menu__link"
                onClick={handleClickProfile}
                name="odysseyAvatarProfile"
              >
                <muse-icon name="user" size="md" />
                Profile
              </muse-button>
            )}
            <muse-button
              button-type="link"
              custom-class="avatar-menu__link"
              onClick={handleClickLogout}
              name="odysseyAvatarLogout"
            >
              <muse-icon name="log-out" size="md" />
              Logout
            </muse-button>
          </header>
          <div className="avatar-menu__body">
            <h4 className="avatar-menu__body__title">Preferences</h4>
            <h5
              className={classNames({
                'avatar-menu__body__option': true,
                'avatar-menu__body__option--disabled': !themesAvailable,
              })}
            >
              Theme
              {!themesAvailable && (
                <muse-tooltip>
                  <div slot="tooltip-trigger">
                    <muse-icon name="info" size="sm" />
                  </div>
                  <div>No themes available</div>
                </muse-tooltip>
              )}
              <muse-button
                button-type="link"
                custom-class="avatar-menu__option"
                onClick={themesAvailable ? handleThemeToggle : null}
                name="odysseyThemeSetting"
                disabled={!themesAvailable}
              >
                <div className={themeClassNames(selectedTheme === 'light')}>
                  <muse-icon name="sun" size="md" />
                </div>
                <div className={themeClassNames(selectedTheme === 'dark')}>
                  <muse-icon name="moon" size="md" />
                </div>
              </muse-button>
            </h5>
            <h5
              className={classNames({
                'avatar-menu__body__option': true,
                'avatar-menu__body__option--disabled': !localesAvailable,
              })}
            >
              Language
              {!localesAvailable && (
                <muse-tooltip>
                  <div slot="tooltip-trigger">
                    <muse-icon name="info" size="sm" />
                  </div>
                  <div>No languages available</div>
                </muse-tooltip>
              )}
              <muse-select custom-class="avatar-menu__option">
                <select
                  name="locale-options"
                  onChange={handleLocaleChange}
                  disabled={!localesAvailable}
                >
                  {locales.map(option => (
                    <option key={option} value={option} selected={option === selectedLocale}>
                      {option}
                    </option>
                  ))}
                </select>
              </muse-select>
            </h5>
          </div>
        </section>
      </AvatarMenuStyles>
    );
  }
}

AvatarMenu.propTypes = {
  displayName: PropTypes.string.isRequired,
  locales: PropTypes.arrayOf(PropTypes.string),
  locale: PropTypes.string,
  themes: PropTypes.arrayOf(PropTypes.oneOf(['dark', 'light'])),
  theme: PropTypes.string,
  avatarConfig: PropTypes.shape({
    yourSettings: PropTypes.shape({
      show: PropTypes.string,
    }),
    profile: PropTypes.shape({
      show: PropTypes.string,
    }),
  }).isRequired,
};

AvatarMenu.defaultProp = {
  styleTarget: null,
  contactEmail: null,
  contactPhone: null,
  locales: ['english'],
  locale: 'english',
  themes: ['dark'],
  theme: 'dark',
};

export default AvatarMenu;
