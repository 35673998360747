/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { render } from 'react-dom';
import * as retargetEvents from 'react-shadow-dom-retarget-events';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { applyPolyfills, defineCustomElements } from '@muse/components/loader';
import { OktaAuth } from '@okta/okta-auth-js';
import jwtDecode from 'jwt-decode';
import config from '../../config/index';
import fetchUserData from '../../graphql/queries/fetchUserData';
import Header from '../../components/Header';
import '../../styles/styles.scss';
import styles from '../../styles/header.styles.scss';

JavascriptTimeAgo.locale(en);
JavascriptTimeAgo.addLocale(en);

applyPolyfills().then(defineCustomElements);

const loadOdysseyState = async (gqlURI, accessTokenDecoded) => {
  const state = {};
  try {
    const {
      data: { userDetails, products },
    } = await fetchUserData(gqlURI, accessTokenDecoded);
    state.userData = {
      ...userDetails,
      apps: products,
    };
  } catch (error) {
    throw new Error(error);
  }
  return state;
};

const createHeader = state => {
  return React.createElement(Header, { ...state }, React.createElement('slot'));
};

class OdysseyHeader extends HTMLElement {
  constructor() {
    super();
    // Defaults
    this.preloadedState = {
      options: {},
      userData: {
        firstName: '-',
        lastName: '-',
        apps: [
          { name: 'Market', displayName: 'Market', linkUrl: 'https://fireeye.market' },
          {
            name: 'FireEye Documentation Portal',
            displayName: 'FireEye Documentation Portal',
            linkUrl: 'https://docs.fireeye.com',
          },
          {
            name: 'FireEye Developer Resources',
            displayName: 'FireEye Developer Resources',
            linkUrl: 'https://fireeye.dev',
          },
        ],
      },
      avatarConfig: {
        yourSettings: {
          show: true,
        },
        profile: {
          show: true,
        },
      },
    };
    this.elementName = 'odyssey-header';
  }

  static get observedAttributes() {
    return [
      'user-data',
      'themes',
      'locales',
      'product',
      'theme',
      'locale',
      'notifications',
      'avatarConfig',
    ];
  }

  getElementName() {
    return this.elementName;
  }

  getUserData() {
    return this.preloadedState.userData;
  }

  observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.type === 'attributes') {
        let {
          userData,
          notifications,
          avatarConfig,
          options: { themes, locales, product },
        } = this.preloadedState;

        if (mutation.attributeName === 'user-data')
          userData = { ...userData, ...JSON.parse(mutation.target.getAttribute('user-data')) };

        if (mutation.attributeName === 'themes')
          themes = JSON.parse(mutation.target.getAttribute('themes'));

        if (mutation.attributeName === 'locales')
          locales = JSON.parse(mutation.target.getAttribute('locales'));

        if (mutation.attributeName === 'product') product = mutation.target.getAttribute('product');

        if (mutation.attributeName === 'notifications')
          notifications = JSON.parse(mutation.target.getAttribute('notifications'));

        if (mutation.attributeName === 'avatarConfig')
          avatarConfig = {
            ...avatarConfig,
            ...JSON.parse(mutation.target.getAttribute('avatarConfig')),
          };

        render(
          createHeader({
            ...this.preloadedState,
            userData,
            notifications,
            avatarConfig,
            options: { ...this.preloadedState.options, locales, themes, product },
          }),
          mutation.target
        );
      }
    });
  });

  async connectedCallback() {
    this.mountPoint = document.createElement('div');
    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(this.mountPoint);

    const style = document.createElement('style');
    style.textContent = styles;
    shadowRoot.appendChild(style);

    if (this.hasAttribute('mfe')) {
      const mfe = this.getAttribute('mfe');
      this.preloadedState.mfe = !mfe ? true : mfe.toLowerCase() === 'true';
    }

    const contextChangeListener = this.getAttribute('context-listener') || 'context-changed';

    if (contextChangeListener) {
      document.addEventListener(contextChangeListener, event => {
        if (event.detail.userData && !this.preloadedState.mfe)
          this.preloadedState.userData = {
            ...this.preloadedState.userData,
            ...event.detail.userData,
          };

        if (event.detail.locales) this.preloadedState.options.locales = event.detail.locales;

        if (event.detail.locale) this.preloadedState.options.locale = event.detail.locale;

        if (event.detail.themes) this.preloadedState.options.themes = event.detail.themes;

        if (event.detail.theme) this.preloadedState.options.theme = event.detail.theme;

        if (event.detail.notifications)
          this.preloadedState.notifications = event.detail.notifications;

        if (event.detail.avatarConfig) this.preloadedState.avatarConfig = event.detail.avatarConfig;

        if (event.detail.product) this.preloadedState.option.product = event.detail.product;

        render(createHeader(this.preloadedState), this.mountPoint);
      });
    }

    if (window?.__ODYSSEY_API__?.userData) {
      this.preloadedState = { ...this.preloadedState, ...window.__ODYSSEY_API__ };
    } else if (this.preloadedState.mfe) {
      const retreiveAccessToken = async idpIssuer => {
        const authClient = new OktaAuth({
          issuer: idpIssuer,
        });

        const { tokenManager } = authClient;

        const accessTokenFromTokenManager = await tokenManager.get('accessToken');

        const accessToken = accessTokenFromTokenManager?.value;

        const decodedAccessToken = jwtDecode(accessToken);

        return decodedAccessToken;
      };

      if (this.hasAttribute('idp-issuer'))
        this.preloadedState.options.idpIssuer = this.getAttribute('idp-issuer');

      if (this.hasAttribute('gql-uri'))
        this.preloadedState.options.gqlURI = this.getAttribute('gql-uri');

      const idpIssuer = this.preloadedState.options.idpIssuer || config.idp.issuer;

      const gqlURI = this.preloadedState.options.gqlURI || config.apis.myfireeye;

      const accessTokenDecoded = await retreiveAccessToken(idpIssuer);

      this.preloadedState = {
        ...this.preloadedState,
        ...(await loadOdysseyState(gqlURI, accessTokenDecoded)),
      };
    }

    const locales = this.getAttribute('locales');
    if (locales) this.preloadedState.options.locales = JSON.parse(locales);

    const locale = this.getAttribute('locale');
    if (locale) this.preloadedState.options.locale = locale;

    const themes = this.getAttribute('themes');
    if (themes) this.preloadedState.options.themes = JSON.parse(themes);

    const theme = this.getAttribute('theme');
    if (theme) this.preloadedState.options.theme = theme;

    const product = this.getAttribute('product');
    if (product) this.preloadedState.options.product = product;

    const userData = this.getAttribute('user-data');
    if (userData) {
      const userDataJSON = JSON.parse(userData);
      this.preloadedState.userData = {
        ...this.preloadedState.userData,
        ...userDataJSON,
      };
    }

    const notifications = this.getAttribute('notifications');
    if (notifications) this.preloadedState.notifications = JSON.parse(notifications);

    const avatarConfig = this.getAttribute('avatarConfig');
    if (avatarConfig)
      this.preloadedState.avatarConfig = {
        ...this.preloadedState.avatarConfig,
        ...JSON.parse(avatarConfig),
      };

    document.dispatchEvent(
      new CustomEvent('odysseyHeaderInit', {
        detail: {
          userData: this.preloadedState.userData,
        },
        bubbles: true,
      })
    );

    this.preloadedState.styleTarget = this.mountPoint;

    this.observer.observe(this.mountPoint, { attributes: true });

    render(createHeader(this.preloadedState), this.mountPoint);

    retargetEvents(shadowRoot);
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (!this.preloadedState.options) this.preloadedState.options = {};

    if (name === 'user-data')
      this.preloadedState.userData = { ...this.preloadedState.userData, ...JSON.parse(newValue) };

    if (name === 'locales') this.preloadedState.options.locales = JSON.parse(newValue);

    if (name === 'locale') this.preloadedState.options.locale = JSON.parse(newValue);

    if (name === 'themes') this.preloadedState.options.themes = JSON.parse(newValue);

    if (name === 'theme') this.preloadedState.options.theme = newValue;

    if (name === 'product') this.preloadedState.options.product = newValue;

    if (name === 'notifications') this.preloadedState.notifications = newValue;

    if (name === 'avatarConfig')
      this.preloadedState.avatarConfig = {
        ...this.preloadedState.avatarConfig,
        ...JSON.parse(newValue),
      };

    if (this.mountPoint) {
      this.preloadedState.styleTarget = this.mountPoint;
      render(createHeader(this.preloadedState), this.mountPoint);
    }
  }
}

customElements.define('odyssey-header', OdysseyHeader);
