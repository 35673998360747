/* eslint-disable no-underscore-dangle */
import fetch from 'node-fetch';
import { ApolloClient, ApolloLink, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { InMemoryCache } from 'apollo-cache-inmemory';

const createAuthLink = accessToken =>
  setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      'x-api-key': 'LOCAL_DEV_API_KEY',
      authorization: `Bearer ${accessToken}`,
    },
  }));

const createRetryingRequestLink = requestLink => {
  const retryLink = new RetryLink({
    delay: {
      initial: 3000,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: error => !!error,
    },
  });
  return ApolloLink.from([retryLink, requestLink]);
};

const requestLink = gqlURI => new HttpLink({ uri: gqlURI, fetch });

const links = (gqlURI, accessToken) => [
  createAuthLink(accessToken),
  createRetryingRequestLink(requestLink(gqlURI)),
];

const client = (gqlURI, accessToken) =>
  new ApolloClient({
    link: from(links(gqlURI, accessToken)),
    cache: new InMemoryCache(),
  });

export default client;
