import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheetManager } from 'styled-components';
import AppSwitcher from '../AppSwitcher';
import Avatar from '../Avatar';
// import OrgSwitcher from '../OrgSwitcher';
import NotificationPopover from './NotificationPopover';

const Header = props => {
  const { children, options, userData, styleTarget, notifications, avatarConfig } = props;
  const { firstName = '', lastName = '', apps = [] } = userData;
  // const { nodes: orgs = [] } = organizations;
  const { product, locales, themes, theme, locale } = options;

  return (
    <StyleSheetManager target={styleTarget}>
      <muse-top-nav home-uri="" product={product}>
        {children}

        {/* TODO: Finalize with data */}
        {/* {orgs.length > 0 && <OrgSwitcher {...props} />} */}

        <muse-top-nav-link slot="nav-links">
          <NotificationPopover notifications={notifications} />
        </muse-top-nav-link>

        {apps && apps.length > 0 && <AppSwitcher {...props} />}

        {firstName && firstName.length > 0 && lastName && lastName.length > 0 && (
          <Avatar
            {...props}
            locales={locales}
            locale={locale}
            themes={themes}
            theme={theme}
            avatarConfig={avatarConfig}
          />
        )}
      </muse-top-nav>
    </StyleSheetManager>
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  userData: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    apps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        label: PropTypes.string,
        linkUrl: PropTypes.string,
        displayName: PropTypes.string,
        displayAssignable: PropTypes.bool,
      })
    ),
    organizations: PropTypes.shape({
      count: PropTypes.number,
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }),
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      category: PropTypes.string,
      title: PropTypes.string,
      message: PropTypes.string,
      createdAt: PropTypes.string,
      isRead: PropTypes.bool,
      isSeen: PropTypes.bool,
      disposition: PropTypes.string,
      source: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  avatarConfig: PropTypes.shape({
    yourSettings: PropTypes.shape({
      show: PropTypes.string,
    }),
    profile: PropTypes.shape({
      show: PropTypes.string,
    }),
  }).isRequired,
  api: PropTypes.shape({
    fetchUserOrganizations: PropTypes.func,
  }),
  options: PropTypes.shape({
    product: PropTypes.string,
    themes: PropTypes.arrayOf(PropTypes.oneOf(['dark', 'light'])),
    locales: PropTypes.arrayOf(PropTypes.string),
    theme: PropTypes.string,
    locale: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  styleTarget: PropTypes.object,
};

Header.defaultProps = {
  children: null,
  userData: {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    apps: [],
    organizations: {
      nodes: [],
      count: null,
    },
  },
  notifications: [],
  api: {
    fetchUserOrganizations: () => {},
  },
  options: {
    product: null,
    productTitle: null,
    locales: ['english'],
    locale: 'english',
    themes: ['dark', 'light'],
    theme: 'dark',
  },
  styleTarget: null,
};

export default Header;
