import React from 'react';
import PropTypes from 'prop-types';
import AvatarMenu from './AvatarMenu';

const Avatar = ({
  userData = {
    firstName: '',
    lastName: '',
    email: '',
  },
  companyContactInfo = {
    email: '',
    phone: '',
  },
  locales,
  locale,
  themes,
  theme,
  avatarConfig,
}) => {
  const { firstName, lastName } = userData;
  const displayName = `${firstName} ${lastName}`.trim();
  const { email: contactEmail, phone: contactPhone } = companyContactInfo;
  return (
    firstName &&
    lastName && (
      <muse-top-nav-dropdown slot="nav-avatar" is-util="true" data-testid="odysseyAvatar">
        <muse-avatar slot="top-nav-dropdown-trigger" user-name={displayName} size="small" />
        <AvatarMenu
          displayName={displayName}
          contactEmail={contactEmail}
          contactPhone={contactPhone}
          locales={locales}
          locale={locale}
          themes={themes}
          theme={theme}
          avatarConfig={avatarConfig}
        />
      </muse-top-nav-dropdown>
    )
  );
};

Avatar.propTypes = {
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  companyContactInfo: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  locales: PropTypes.arrayOf(PropTypes.string),
  locale: PropTypes.string,
  themes: PropTypes.arrayOf(PropTypes.oneOf(['dark', 'light'])),
  theme: PropTypes.string,
  avatarConfig: PropTypes.shape({
    yourSettings: PropTypes.shape({
      show: PropTypes.string,
    }),
    profile: PropTypes.shape({
      show: PropTypes.string,
    }),
  }).isRequired,
};

Avatar.defaultProps = {
  styleTarget: null,
  companyContactInfo: {},
  locales: ['english'],
  locale: 'english',
  themes: ['dark'],
  theme: 'dark',
};

export default Avatar;
